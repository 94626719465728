<template>
  <div class="datails-page">
    <!-- <zy-header></zy-header> -->
    <div class="content">
      <div class="left">
        <div class="news-title">
          <div class="title-name">{{ newsData.newsTitle }}</div>
          <div class="title-time">{{ '发布时间：' + newsData.sendOutTime }}</div>
        </div>
        <div class="news-content" v-html="newsData.newsContent">
          <img src="" alt="" />
        </div>
      </div>
      <div class="right">
        <div v-if="lastNews" class="page-news up" @click="newsDetail(lastNews.id)">
          <div class="p-title">上一篇</div>
          <div class="p-info">
            <span v-if="lastNews">{{ lastNews.newsTitle }}</span>
            <span v-else>没有更多了</span>
          </div>
        </div>
        <div v-if="nextNews && lastNews" class="line"></div>
        <div v-if="nextNews" class="page-news down" @click="newsDetail(nextNews.id)">
          <div class="p-title">下一篇</div>
          <div class="p-info">
            <span v-if="nextNews">{{ nextNews.newsTitle }}</span>
            <span v-else>没有更多了</span>
            <!-- {{nextNews.newsTitle ? nextNews.newsTitle : '没有更多了~'}} -->
          </div>
        </div>
      </div>
    </div>
    <zy-footer></zy-footer>
  </div>
</template>

<script>
import { newsDetail } from './api';
export default {
  data() {
    return {
      newsData: {},
      lastNews: {},
      nextNews: {},
    };
  },
  methods: {
    newsDetail(id) {
      if (!id) {
        return;
      }
      let params = {
        id: id,
      };
      newsDetail(params).then(({ result = {} }) => {
        this.newsData = result;
        this.lastNews = result.lastNews;
        this.nextNews = result.nextNews;
      });
    },
  },
  mounted() {
    let id = this.$route.query.id;
    this.newsDetail(id);
  },
};
</script>

<style lang="scss" scoped>
.datails-page {
  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
  }

  .left {
    width: 890px;
    margin: 0 auto;
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .right {
    width: 310px;
    height: 100px;
    margin-top: 140px;
    margin-right: 6vw;
  }

  .news-title {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 30px;
  }

  .title-name {
    font-size: 36px;
    font-weight: 600;
    color: #222222;
    line-height: 46px;
  }

  .title-time {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }

  .news-content {
    margin-bottom: 120px;
    padding-top: 30px;
  }

  .news-content {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 30px;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
      margin: 0;
      padding: 0;
    }
  }

  // .right
  .page-news {
    margin-bottom: 26px;
  }

  .p-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #333333;
    margin-bottom: 20px;
  }

  .page-news {
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #222222;
  }

  .down {
    padding-top: 26px;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #eaeaea;
  }
}

.datails-page /deep/ {
  .news-content {

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
      margin: 0;
      padding: 0;
    }

    img {
      display: inline-block;
      text-align: center;
      margin: 30px 0 30px 78px;
      width: auto !important;
      max-width: 670px;
      height: auto !important;
    }
  }
}
</style>
