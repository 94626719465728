/**
 
 * 常用的全局工具以及全局信息设置
 * 
 
**/ 
import httpConfig from './ajaxConfig'
export default {
  baseImgUrl() {
    const imgUrl = httpConfig.baseURL
    return imgUrl
  }
}