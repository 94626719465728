<template>
  <div class="dynamic-page">
    <zy-header :url="topBg"></zy-header>
    <div class="content">
      <z-nav :active="activeName" :showSearch="false" @changeTab="handleClick" :list="dynamicData"
        @confirm="confirmInput"></z-nav>
      <div v-if="total != 0" class="news-list">
        <div class="news-item" v-for="(item, i) in newsList" :key="i" @click="go(item)">
          <div class="left-img">
            <div class="video-info" v-if="item.newsType === 'cbdt'">
              <img :src="item.showImgSrc" alt="" />
              <img class="play-icon" src="@/assets/image/play.png" alt="" />
            </div>
            <img v-else :src="item.showImgSrc" alt="" />
          </div>
          <div class="right-infos">
            <div class="news-title">
              <div class="title-name over-flow1">{{ item.newsTitle }}</div>
              <div class="title-type" v-show="item.newsType !== 'cbdt'">
                {{ item.newsType_dictText }}
              </div>
            </div>
            <div class="news-desc over-flow2" v-html="item.newsIntroduce"></div>
            <div class="news-times">
              <zy-icon name="icon-shijian" size="14" color="#999999"></zy-icon>
              <span>{{ item.sendOutTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="total == 0" class="news-empty">
        <el-empty description="暂无数据"></el-empty>
      </div>
      <div v-if="total > 5" class="pages">
        <el-pagination hide-on-single-page @current-change="handleCurrentChange" layout="prev, pager, next"
          :page-size="5" :total="total">
        </el-pagination>
      </div>
    </div>
    <w-video :videoinfo="videoInfo" :show="playV" @close="closeVideo"></w-video>
    <zy-footer></zy-footer>
  </div>
</template>

<script>
import { getNewsList, spreadPage } from './api';
import data from '@/utils/data';
import topBg from '@/assets/image/gsdt-top-bg.png';
import wVideo from '@/components/winVideo.vue';

export default {
  mixins: [data],
  data() {
    return {
      topBg,
      activeName: 'qyyw',
      newsList: [],
      // pageInfo
      pageNo: 1,
      pgeSize: 5,
      total: 0,
      // cbdt 走单独jie'kou
      searchContent: '',
      //
      playV: false,
      videoInfo: {
        videoUrl: '',
        poster: '',
        title: '',
      },
    };
  },
  components: {
    wVideo,
  },
  methods: {
    handleClick(val) {
      this.activeName = val.name;
      this.pageNo = 1;
      this.search();
    },
    confirmInput(val) {
      this.searchContent = val;
      this.search();
    },
    search() {
      let params = {
        newType: this.activeName,
        pageNo: this.pageNo,
        pageSize: 5,
        searchContent: this.searchContent,
      };
      if (params.newType == 'cbdt') {
        spreadPage(params).then(({ result }) => {
          this.total = result.total;
          let list = result.records;
          list.forEach((item) => {
            item.newsType = 'cbdt';
            item.newsIntroduce = item.newsContent;
            if (item.showImgSrc && item.showImgSrc.indexOf('http') == -1) {
              item.showImgSrc = this.$utils.baseImgUrl() + item.showImgSrc;
            }
            if (item.videoSrc && item.videoSrc.indexOf('http') == -1) {
              item.videoSrc = this.$utils.baseImgUrl() + item.videoSrc;
            }
          });
          // if need do something
          // if ()
          this.newsList = list;
        });
      } else {
        getNewsList(params).then(({ result = {} }) => {
          this.total = result.total;
          let list = result.records;
          list.forEach((item) => {
            if (item.showImgSrc && item.showImgSrc.indexOf('http') == -1) {
              item.showImgSrc = this.$utils.baseImgUrl() + item.showImgSrc;
            }
          });
          // if need do something
          this.newsList = list;
        });
      }
    },
    clearSearch() {
      this.searchContent = '';
      this.search();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.search();
    },
    go(val) {
      if (val.newsType !== 'cbdt') {
        let params = { id: val.id };
        this.$router.push({
          path: '/dynamic/details',
          query: params,
        });
      } else {
        this.videoInfo.poster = val.showImgSrc;
        this.videoInfo.videoUrl = val.videoSrc;
        this.videoInfo.title = val.newsTitle;
        this.playV = true;
      }
    },
    closeVideo() {
      this.playV = false;
      this.videoInfo = {};
    },
  },
  mounted() {
    this.activeName = this.$route.query.type || 'qyyw';
    this.search();
  },
  beforeRouteUpdate(to) {
    this.activeName = to.query.type;
    this.search();
  },
};
</script>

<style lang="scss">
.dynamic-page {
  .news-list {
    margin-bottom: 120px;
    min-height: 400px;
  }

  .news-empty {
    margin: 80px 0 140px;
    height: 400px;
    box-sizing: border-box;
  }

  .news-item {
    display: flex;
    margin-top: 50px;

    &:hover {
      .title-name {
        color: #af406c;
      }
    }

    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;

    &:last-child {
      border: none;
    }
  }

  .left-img {
    width: 280px;
    height: 190px;
    margin-right: 40px;

    img,
    video,
    .video-info {
      width: 280px;
      height: 190px;
      border-radius: 6px;
    }

    .video-info {
      cursor: pointer;
      position: relative;

      &::after {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.2);
        content: ' ';
        z-index: 10;
      }
    }

    .play-icon {
      position: absolute;
      width: 60px;
      height: 60px;
      left: 50%;
      z-index: 14;
      top: 50%;
      opacity: 0.8;
      margin-left: -30px;
      margin-top: -30px;
    }
  }

  .right-infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
    width: 100%;
    cursor: pointer;
  }

  .news-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title-name {
    width: 700px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
  }

  .title-type {
    width: 76px;
    height: 28px;
    border-radius: 4px;
    border: 1px solid #af406c;
    font-size: 13px;
    font-weight: 400;
    color: #af406c;
    line-height: 28px;
    text-align: center;
  }

  .news-desc {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 30px;
    margin-top: -30px;
  }

  .news-times {
    height: 20px;
    line-height: 20px;

    span {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      margin-left: 10px;
    }
  }

  .pages {
    text-align: right;
    margin-top: -80px;
    margin-bottom: 80px;

    .el-pager li,
    .el-pagination button {
      background: none;
    }

    .el-pagination button:disabled {
      background: none;
    }
  }
}

// ele
.dynamic-page {
  .el-tabs__item {
    height: 45px;
    font-size: 18px;
    font-weight: 400;
    color: #999999;
    line-height: 25px;
    // &::before {
    //   content: '';
    //   position: absolute;
    //   width: 1px;
    //   height: 25px;
    //   background: #ddd;
    //   top: 1px;
    //   right: 0px;
    // }
    // &:last-child::before {
    //   display: none;
    // }
  }

  .el-tabs__item.is-active,
  .el-tabs__item:hover {
    color: #000000;
  }

  .el-tabs__active-bar {
    background-color: #af406c;
  }

  .el-tabs__nav-wrap::after {
    background: #eaeaea;
  }

  .el-input--suffix .el-input__inner {
    background: #f5f5f5;
  }

  .el-pager {
    color: #666;
  }

  .el-pager li.active,
  .el-pager li:hover {
    color: #af406c;
  }
}
</style>
