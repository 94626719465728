import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import utils from './utils/util'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import VueWechatTitle from 'vue-wechat-title' //

import 'lib-flexible'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import Icon from '@/components/Icon'
import Footer from '@/components/foot'
import Header from '@/components/publicHead'
import zNav from '@/components/pageNavMenu.vue'

Vue.use(ElementUI)
Vue.use(VueWechatTitle);
Vue.component('zy-icon', Icon)
Vue.component('zy-header', Header)
Vue.component('zy-footer', Footer)
Vue.component('z-nav', zNav)
Vue.config.productionTip = false;
Vue.prototype.$utils = utils;
Vue.prototype.$video = Video

// Vue.prototype.$store = store;
// Vue.prototype.$dispatch = (...args) => store.dispatch(...args);
// Vue.prototype.$commit = (...args) => store.commit(...args);



new Vue({
  router,
  // store,
  render: h => h(App),
}).$mount('#app')