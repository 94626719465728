<template>
  <div class="public-head">
    <div class="pub-img" :style="{ backgroundImage: 'url(' + bgUrl + ')' }">
      <!-- <img :style="headStyle" :src="url ? url : defaultBg" /> -->
      <div class="pub-title" v-if="text.title">{{ text.title || '' }}</div>
      <div class="pub-subTitle" v-if="text.enTitle">{{ text.enTitle || '' }}</div>
    </div>
  </div>
</template>

<script>
import defaultBg from '@/assets/image/public-head.png';
export default {
  props: {
    url: String,
    height: String,
    text: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      defaultBg,
    };
  },
  computed: {
    headStyle() {
      let style = {};
      this.height && (style.height = this.height);
      return style;
    },
    bgUrl() {
      console.log(this.text, 'gdx');
      return this.url ? this.url : this.defaultBg;
    },
  },
};
</script>

<style lang="scss" scoped>
.public-head {
  height: 450px;
  width: 100%;
  .pub-img {
    @extend .f-cloum-center, .b-box;
    padding-top: 90px;
    height: 450px;
    width: 100%;
    background-size: 100% 100%;
  }

  .pub-title {
    font-weight: 600;
    font-size: 36px;
    color: #ffffff;
    margin-bottom: 18px;
    text-shadow: 0px 0px 20px #242b8f;
  }
  .pub-subTitle {
    @extend .pub-title;
    font-size: 24px;
  }
}
</style>
