<template>
  <div class="technology-page">
    <zy-header
      :text="{ title: '产品体系', enTitle: 'Our Product' }"
      :url="topBg"
    ></zy-header>
    <div class="content">
      <div class="info-list">
        <!-- 循环傀儡 -->
        <div class="info-cyclic-item">
          <div class="prd-list">
            <div
              class="prd-item"
              v-for="(item, i) in list"
              :key="i"
              v-show="item.title"
              @click="goDetail(item)"
            >
              <div class="prd-item-img">
                <img :src="item.img" :alt="item.title" />
              </div>
              <div class="prd-item-content">
                <div class="prd-item-title over-flow1">
                  {{ item.title || '-' }}
                </div>
                <div class="prd-item-subtitle over-flow2">
                  {{ item.desc || '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <zy-footer></zy-footer>
  </div>
</template>

<script>
import img1 from '@/assets/image/product/cp-1.png';
import img2 from '@/assets/image/product/cp-2-1.jpg';
import img3 from '@/assets/image/product/cp-3.png';
import img4 from '@/assets/image/technology/4.png';
import img5 from '@/assets/image/product/cp-5.png';
import rightIcon from '@/assets/image/technology/right.png';
import topBg from '@/assets/image/jsnl-top-bg.png';

export default {
  data() {
    return {
      topBg,
      rightIcon,
      list: [
        {
          title: '全球视听信息分析平台',
          img: img1,
          id: 'te1',
          desc:
            '该系统通过分布式数据挖掘技术、多语种OCR识别技术、多语种语音识别技术、NLP技术，实现全球视听...',
          path: '/technology/solution/solution01',
          infos: [
            // { text: "超过1900万专项分类任务影像数据集" },
            // { text: "超过250万个标签的物体检测、分割数据集" },
            // { text: "超过500万版权素材，100万小时版权视频标注素材" },
          ],
        },
        {
          title: '直播信息分析系统',
          img: img2,
          id: 'te1',
          path: '/technology/solution/solution02',
          desc:
            '直播信息分析平台通过RPA技术、关键帧抽取算法、音视频解码技术，实现对直播流的关键信息获取，再利...',
          infos: [
            // { text: "超过1900万专项分类任务影像数据集" },
            // { text: "超过250万个标签的物体检测、分割数据集" },
            // { text: "超过500万版权素材，100万小时版权视频标注素材" },
          ],
        },
        {
          title: '影像内容检测系统',
          img: img3,
          path: '/technology/solution/solution03',
          desc:
            '该系统通过多目标图像识别算法对视频流进行处理，实现对动态影像中多主体的识别与检测。',
          infos: [
            { text: '应用于文化传媒、游戏娱乐、科技教育和跨境电商等场景' },
            { text: '在大规模视觉内容分析场景下提高了分析的准确率和效率' },
          ],
        },
        {
          title: '智能视听内容生产系统',
          img: img4,
          path: '/technology/solution/solution04',
          desc:
            '该系统通过关键帧识别，拆条，精准提取等方式智能处理视频素材，快速生成目标元视频库。利用知识图谱...',
          infos: [
            { text: '应用于文化传播等场景' },
            { text: '为大规模影像传播提供真实有效的信息内容' },
          ],
        },
        {
          title: '智能人机交互系统',
          img: img5,
          path: '/technology/solution/solution05',
          desc:
            '该系统可自动化模拟用户行为，助力高效矩阵传播。利用RPA技术、多领域知识图谱及用户画像知识库，实...',
          infos: [],
        },
      ],
    };
  },
  methods: {
    goDetail(e) {
      this.$router.push(e.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.technology-page {
  .content {
    // width: 100%;
  }
  .info-list {
    // margin-top: 100px;
    margin-bottom: 120px;
  }
  .info-item {
    display: flex;
    margin-bottom: 60px;
    // justify-content: space-between;
    img {
      width: 540px;
      height: 335px;
    }
  }
  .info-text {
    width: 600px;
  }
  .info-title {
    width: 600px;
    height: 45px;
    line-height: 45px;
    display: flex;
    margin-bottom: 32px;
  }
  .info-index {
    font-size: 20px;
    font-weight: 400;
    color: #af406c;
    width: 28px;
    padding-bottom: 15px;
    text-align: center;
    border-bottom: 3px solid #000;
  }
  .info-name {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 15px;
    width: 572px;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    color: #050505;
  }

  .info-desc {
    width: 600px;
    height: 105px;
    font-size: 16px;
    line-height: 35px;
    font-weight: 400;
    color: #333;
    margin-bottom: 24px;
  }

  .info-tag {
    margin-top: 12px;
    display: flex;
    align-items: center;
    .icon-circle {
      height: 24px;
      width: 24px;
      line-height: 24px;
      text-align: center;
      background: #af406c;
      border-radius: 100%;
    }
  }
  .info-tag-text {
    margin-left: 15px;
    height: 35px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #67646f;
    line-height: 35px;
  }
  // web2.0 ↓
  .prd-list {
    display: flex;
    flex-wrap: wrap;
  }
  .prd-item {
    margin-right: 30px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    width: 430px;
    height: 435px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #e5e5e5;
    background: #fff;
    box-sizing: border-box;
    margin-bottom: 40px;
    cursor: pointer;
    &:hover {
      .prd-item-title {
        color: #af406c;
      }
    }

    img {
      width: 430px;
      height: 265px;
    }
    .prd-item-content {
      height: 170px;
      padding: 20px;
      box-sizing: border-box;
      overflow: hidden;
    }
    .prd-item-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #050505;
      margin-bottom: 12px;
    }
    .prd-item-subtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 35px;
      color: #333;
    }
  }
}
</style>
