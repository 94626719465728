<template>
  <div class="pub-nav-bar">
    <div class="tab-bar">
      <div class="left-tabs">
        <el-tabs v-model="selected" @tab-click="handleClick($event)">
          <el-tab-pane
            v-for="(item, i) in list"
            :key="i"
            :label="item.label"
            :name="item.value"
          ></el-tab-pane>
        </el-tabs>
        <div v-if="showSearch" class="right-search">
          <el-input
            v-model="searchContent"
            :clearable="clearable"
            @clear="clearSearch"
            @keyup.enter.native="inputSearch()"
            placeholder="请输入关键词搜索"
          >
            <i
              class="el-icon-search el-input__icon"
              slot="suffix"
              @click="clickSearch"
            >
            </i>
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
    },
    active: {
      type: String,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.selected = this.active;
  },
  data() {
    return {
      searchContent: "",
      selected: "",
      showData: [],
    };
  },
  watch: {
    active(val) {
      this.selected = val;
    },
  },
  methods: {
    handleClick(val) {
      let data = val.$options.propsData;
      data.index = val.index;
      this.$emit("changeTab", data);
    },
    clickSearch() {},
    clearSearch() {
      this.searchContent = "";
      this.clickSearch();
    },
    inputSearch() {
      this.$emit("confirm", this.searchContent);
    },
  },
};
</script>
<style lang="scss" scoped>
.pub-nav-bar {
  margin-bottom: 40px;
  .tab-bar {
    position: relative;
  }
  .right-search {
    height: 40px;
    width: 400px;
    position: absolute;
    right: 0;
    top: -10px;
  }

  &/deep/ {
    .el-tabs__active-bar {
      height: 3px;
      background: rgba($color: rgba(110, 89, 208, 1), $alpha: 0.8);
    }
    .el-tabs__item {
      height: 45px;
      font-size: 18px;
      font-weight: 400;
      color: #999999;
      line-height: 25px;
    }
    .el-tabs__item.is-active,
    .el-tabs__item:hover {
      color: #000000;
    }
    .el-tabs__nav-wrap::after {
      background: #eaeaea;
    }
    .el-input--suffix .el-input__inner {
      background: #f5f5f5;
    }
    .el-pager {
      color: #666;
    }
  }
}
</style>