<template>
  <div class="window-video" @click.stop="close" v-if="show">
    <div class="w-area" @click.stop="clickNull">
      <div class="v-title">
        <div class="title">{{ videoinfo.title }}</div>
        <div class="close" @click="close">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="v-video">
        <Video id="vid" class="" controls preload="auto" :autoplay="videoinfo.autoPlay" :poster="videoinfo.poster">
          <source :src="videoinfo.videoUrl" type="video/mp4">
        </Video>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    videoinfo: {
      type: Object,
      default: () => {
        return {
          videoUrl: '',
          poster: '',
        }
      },
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    clickNull() {
      return
    }
  },
}
</script>
<style lang="scss" scoped>
.p-fixed {
  position: fixed
}

.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.b-box {
  box-sizing: border-box;
}

.f-between {
  display: flex;
  justify-content: space-between;
}

.f24 {
  font-size: 24px
}

.window-video {
  @extend .p-fixed, .f-center;
  height: 100vh;
  width: 100vw;
  background: rgba($color: #000, $alpha: .2);
  top: 0;
  left: 0;
  z-index: 100;
}

.close {
  cursor: pointer;
}

.w-area {
  padding: 20px;
  height: 80%;
  width: 70%;
  background: #fff;
  border-radius: 10px;

}

.v-title {
  @extend .f-between, .f24;
  height: 40px;
  line-height: 40px;

}

.v-video {
  @extend .f-center, .b-box;
  height: 100%;
  width: 100%;
  // background: pink;
  padding: 20px;
  padding-top: 0;

  video {
    margin-top: -10px;
    height: 92%;
    width: 100%;
    border-radius: 10px;
  }
}
</style>