import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    activeIdnex: '0'
  },
  getters: {},
  mutations: {
    SET_ACTIVEINDEX(state, payload) {
      state.activeIdnex = payload
    }
  },
  actions: {

  },
  modules: {},
  plugins: [createPersistedState()]
})