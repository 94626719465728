<template>
  <div class="product-page">
    <zy-header :text="headText" :url="headBg"></zy-header>
    <div class="content">
      <z-nav
        :active="activeName"
        :list="prodectData"
        :showSearch="false"
        @changeTab="handleClick"
        class="nav-content"
      ></z-nav>
      <div class="prd-list">
        <!-- <div class="prd-item" v-for="(item, i) in showData" :key="i" v-show="item.title">
              <div class="prd-item-img">
                <img :src="item.img" :alt="item.title">
              </div>
              <div class="prd-item-content">
                <div class="prd-item-title over-flow1">{{item.title || '-'}}</div>
                <div class="prd-item-subtitle over-flow2">{{item.desc  || '-'}}</div>
              </div>
    
            </div> -->
        <div
          class="info-cyclic-item"
          v-for="(item, i) in showData"
          :key="i"
          v-show="item.title"
          @click="goDetail(item)"
        >
          <!-- 条件判断循环 -->
          <div :id="item.id" class="info-cyclic-id"></div>
          <div v-if="i % 2 == 0" class="info-item">
            <!-- 两个元素一左一右 -->
            <div class="info-left" style="margin-right: 60px">
              <img :src="item.img" />
            </div>
            <div class="info-right info-text">
              <div class="info-t-content">
                <div class="info-title">
                  <div class="info-index">{{ '0' + ++i }}</div>
                  <div class="info-name">{{ item.title }}</div>
                </div>
                <div class="info-desc over-flow3">{{ item.desc }}</div>
                <div class="info-tags" v-for="(citem, ci) in item.infos" :key="ci">
                  <div class="info-tag">
                    <span class="info-tag-text">{{ citem.text }}</span>
                  </div>
                </div>
              </div>
              <div class="info-t-btn">
                <span>了解更多</span>
                <img :src="rightIcon" />
              </div>
            </div>
          </div>
          <div v-else class="info-item">
            <!-- 两个元素一左一右 -->
            <div class="info-right info-text" style="margin-right: 60px">
              <div class="info-t-content">
                <div class="info-title">
                  <div class="info-index">{{ '0' + ++i }}</div>
                  <div class="info-name">{{ item.title }}</div>
                </div>
                <div class="info-desc over-flow3">{{ item.desc }}</div>
                <div class="info-tags" v-for="(citem, ci) in item.infos" :key="ci">
                  <div class="info-tag">
                    <span class="info-tag-text">{{ citem.text }}</span>
                  </div>
                </div>
              </div>
              <div class="info-t-btn">
                <span>了解更多</span>
                <img :src="rightIcon" />
              </div>
            </div>
            <div class="info-left">
              <img :src="item.img" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <zy-footer></zy-footer>
  </div>
</template>

<script>
import jjfaBg from '@/assets/image/jjfa-top-bg.png';
import data from '@/utils/data';
import rightIcon from '@/assets/image/technology/right.png';

export default {
  mixins: [data],
  data() {
    return {
      headBg: jjfaBg,
      activeName: '',
      rightIcon,
      activeIndex: 0,
      showData: [],
      headText: { title: '解决方案', enTitle: 'Solution' },
    };
  },
  watch: {
    '$route.query.id'(id) {
      let index = this.prodectData.findIndex((item) => {
        return id == item.value;
      });
      this.activeName = id;
      this.showData = this.prodectData[index].children;
    },
  },
  methods: {
    handleClick(val) {
      this.showData = this.prodectData[val.index].children;
      this.activeName = val.name;
    },
    goDetail(e) {
      this.$router.push(e.path);
    },
  },
  mounted() {
    let key = this.$route.query.id;
    key && (this.activeName = key);
    key == 'gjcb' && (this.activeIndex = 1);
    key == 'kjds' && (this.activeIndex = 2);
    this.showData = this.prodectData[this.activeIndex].children;
  },
};
</script>

<style lang="scss" scoped>
.product-page {
  .content {
    width: 100%;
    padding: 0;
    padding-top: 80px;
  }
  .nav-content {
    padding: 0 calc((100vw - 1350px) / 2);
  }
  .pub-nav-bar {
    margin-bottom: 0px;
  }
  .info-list {
  }
  .info-item {
    display: flex; // margin-bottom: 60px;
    height: 335px;
    padding: 60px calc((100vw - 1350px) / 2);
    background: #f2f2f2; // justify-content: space-between;
  }
  .info-cyclic-item {
    position: relative;
    &:nth-child(2n) {
      .info-item {
        background: #fff;
      }
    }
  }
  .info-cyclic-id {
    position: absolute;
    top: -100px;
  }
  .info-left {
    img {
      width: 540px;
      height: 335px;
      border-radius: 10px;
    }
  }
  .info-text {
    width: 750px;
    display: flex;
    flex-direction: column; // justify-content: space-between;
  }
  .info-t-btn {
    @extend .mt20;
    width: 220px;
    height: 42px;
    font-size: 16px;
    border: 1px solid #0c173b;
    line-height: 42px;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    img {
      // display: inline-block;
      width: 22px;
      height: 4.5px;
      margin-top: 22px;
      margin-left: 4px; // line-height: 10px;
    }
  }
  .info-title {
    width: 100%;
    height: 45px;
    line-height: 45px;
    display: flex;
    margin-bottom: 20px;
  }
  .info-index {
    font-size: 22px;
    font-weight: 400;
    color: #af406c;
    width: 28px;
    padding-bottom: 15px;
    text-align: center; // border-bottom: 3px solid #000;
  }
  .info-name {
    // border-bottom: 1px solid #eaeaea;
    padding-bottom: 15px;
    width: 722px;
    font-size: 22px;
    padding-left: 20px;
    color: #050505;
    font-weight: 600;
    font-family: PingFangSC-Semibold, PingFang SC;
  }
  .info-desc {
    // width: 600px;
    // height: 105px;
    font-size: 16px;
    line-height: 35px;
    font-weight: 400;
    color: #333;
    margin-bottom: 14px;
  }
  .info-tags {
    margin-top: 14px;
    line-height: 14px;
  }
  .info-tag {
    margin-top: 14px;
    display: flex;
    align-items: center;
    .icon-circle {
      height: 24px;
      width: 24px;
      line-height: 24px;
      text-align: center;
      background: #af406c;
      border-radius: 100%;
    }
  }
  .info-tag-text {
    font-weight: 400;
    font-size: 14px;
    color: #848484;
  } // web2.0 ↓
  .prd-list {
    display: flex;
    flex-wrap: wrap;
  }
  .prd-item {
    margin-right: 30px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    width: 430px;
    height: 435px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    margin-bottom: 40px;
    cursor: pointer;
    &:hover {
      .prd-item-title {
        color: #af406c;
      }
    }
    img {
      width: 430px;
      height: 265px;
    }
    .prd-item-content {
      height: 170px;
      padding: 20px;
      box-sizing: border-box;
      overflow: hidden;
    }
    .prd-item-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #050505;
      margin-bottom: 12px;
    }
    .prd-item-subtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 35px;
      color: #333;
    }
  }
}
</style>
