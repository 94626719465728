<template>
  <div class="company-page">
    <zy-header></zy-header>
    <div class="content">
      <div class="page-title">
        <div>公司简介</div>
      </div>
      <div class="con-desc">
        中译文娱是一家人工智能视觉传播科技企业，隶属于中国出版集团旗下中国对外翻译总公司，专注于AI视觉信息分析、国际传播和新消费领域；自主研发了图像识别、视频语义分析、多模态内容理解和人机交互等核心技术引擎；构建起覆盖文化传媒、游戏娱乐、科技教育和跨境电商等行业应用生态链接产业集群，推动下一代AI视觉科技与行业融合发展，赋能产业数字化转型。目前在北京、青岛两地已开设公司。
      </div>
      <div class="con-img">
        <img src="@/assets/image/company/8.png" alt="" />
      </div>
      <div class="con-desc">
        中译文娱致力于视觉场景的技术研发及问题解决。视觉场景不同于文本场景，视觉场景包含视频、图像、语音等多模态信息，具备信息格式丰富、信息背景多样，信息处理难度极大等特点。中译文娱自主研发了国内领先的以影像数据为核心的数据挖掘技术、基于多计算引擎的影像内容分析技术、智能影像生产技术和跨平台视听信息国际传播技术等多项核心技术，建立了从视觉素材汇聚、数据治理（内容理解、精细化提取、结构化存储）、内容生产、内容分发于一体的综合性人工智能处理平台，可覆盖视觉业务的全生命周期，全链路助力降本增效。
      </div>
      <div class="center-title">- 发展历程 -</div>
      <!-- <div class="con-img" style="backgroud-color: pink">
        <img src="@/assets/image/company/9.png" alt="">
      </div> -->
      <div class="con-img" style="backgroud-color: pink">
        <img src="@/assets/image/company/9_2.png" alt="" />
      </div>
      <div class="center-title">- 战略部署 -</div>
      <div class="con-img">
        <img src="@/assets/image/company/10_3.png" alt="" />
      </div>
      <div class="center-title">- 主营业务 -</div>
      <div class="con-img">
        <img src="@/assets/image/company/10.png" alt="" />
      </div>
      <div class="con-img" style="margin-top: 30px">
        <img src="@/assets/image/company/11.png" alt="" />
      </div>
      <div class="con-img" style="margin-top: 30px">
        <img src="@/assets/image/company/12.png" alt="" />
      </div>
    </div>
    <zy-footer></zy-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.company-page {
  .content {
    padding-top: 100px;
    padding-bottom: 120px;
  }
  .page-title {
    height: 40px;
    font-size: 28px;
    font-weight: 400;
    color: #222222;
    text-align: center;
  }

  .con-desc {
    margin-top: 30px;
    margin-bottom: 30px;
    text-indent: 32px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 30px;
  }
  .con-img img {
    width: 1200px;
  }
  .sub-title {
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #5e568e;
    line-height: 28px;
    margin: 30px 0;
  }
  .center-title {
    text-align: center;
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #5e568e;
    line-height: 28px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
</style>
