import axios from 'axios';
import Vue from 'vue';
import Env from './ajaxConfig';

let v = new Vue();
let config = {
  timeout: 6000,
  baseUrl: Env.baseURL
}
// 配置axios
const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    const configCopy = {
      ...config
    };
    // Do something before request is sent
    configCopy.method = (configCopy.method || 'get').toLowerCase();
    const {
      method,
      params,
      data
    } = configCopy;
    if (['get', 'delete'].includes(method) && !params && data) {
      configCopy.params = data;
      delete configCopy.data;
    }

    const {
      url
    } = configCopy;
    if (!/^(https?:)?\//.test(url)) {
      configCopy.url = `${configCopy.baseUrl}${url}`;
    }

    if (configCopy.data && !(configCopy.data instanceof FormData)) {
      // const formData = new FormData();
      // Object.entries(configCopy.data).forEach(([key, value]) => {
      //   formData.append(key, value);
      // });
      configCopy.headers['Content-Type'] = 'application/json'
      configCopy.data = JSON.stringify(configCopy.data);
    }
    return configCopy;
  },
  function (error) {
    // Do something with request error
    console.log(error)
    return Promise.reject(error);
  },
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    // TODO error 不为0的时候需要全局 Toast 提示错误
    const {
      config,
      data
    } = response;
    if (data.error !== 0 && data.code !== 200) {
      const {
        flags = {}
      } = config;
      if (flags.globalMessage !== false) {
        v.$message.error(data.message);
      }
      return Promise.reject(data.message);
    } else {
      return data;
    }
  },
  function (error) {
    if (error.message.startsWith('timeout of')) {
      return Promise.reject('上传超时！');
    }
    return Promise.reject(error);
  },
);

// 将get, delete 方法的便捷调用方式改成和 post, put 一样的签名，方便使用
patchMethod('get');
patchMethod('delete');

export default _axios;

function patchMethod(method) {
  const originMethod = _axios[method];

  _axios[method] = function (url, data, config = {}) {
    config.params = data;
    return originMethod.call(this, url, config);
  };
}