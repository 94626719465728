export default {
  data() {
    return {
      navData: [
        {
          index: '0',
          value: '首页',
          path: '/',
          children: [],
        },
        {
          index: '1',
          value: '解决方案',
          path: '/product?id=qqst',
          children: {},
        },
        {
          index: '2',
          value: '产品体系',
          path: '/technology',
          children: [
            {
              label: '全球视听信息分析平台',
              path: '/technology/solution/solution01',
            },
            {
              label: '直播信息分析系统',
              path: '/technology/solution/solution02',
            },
            {
              label: '影像内容检测系统',
              path: '/technology/solution/solution03',
            },
            {
              label: '智能视听内容生产系统',
              path: '/technology/solution/solution04',
            },
            {
              label: '智能人机交互系统',
              path: '/technology/solution/solution05',
            },
          ],
        },
        {
          index: '3',
          value: '新闻动态',
          path: '/dynamic',
          children: [
            {
              label: '企业要闻',
              type: 'qyyw',
            },
            {
              label: '业务动态',
              type: 'xwdt',
            },
            {
              label: '传播动态',
              type: 'cbdt',
            },
          ],
        },
        {
          index: '4',
          value: '关于我们',
          path: '/aboutUs',
          children: [
            {
              label: '公司简介',
              type: 'gsjj',
              path: '/aboutUs/company',
            },
            {
              label: '联系我们',
              type: 'lxwm',
              path: '/aboutUs/contact',
            },
            {
              label: '加入我们',
              type: 'jrwm',
              path: '/aboutUs/join',
            },
          ],
        },
      ],
      prodectData: [
        {
          label: '全球视听内容分析',
          value: 'qqst',
          children: [
            {
              title: '视频信息分析解决方案',
              img: require('@/assets/image/product/cp-14.png'),
              desc: '视频信息分析解决方案基于全球海量主流视听平台视频内容或本地视频资源素材，通过“以影像数据为核心的数据挖掘”和“多计算引擎的影像内容分析”两项自主研发的核心技术，实现对视频内容的多维信息分析，为客户提供涵盖全球视频信息分析、热点趋势分析和视频内容智能识别与审核等多维度服务的解决方案。',
              path: '/product/solution/product01',
            },
            {
              title: '直播信息分析解决方案',
              img: require('@/assets/image/product/cp-15.png'),
              desc: '直播信息分析解决方案通过RPA技术、关键帧抽取算法、音视频解码技术等技术实现对直播流的关键信息获取，再利用图像审核算法、文本审核算法，语音识别算法，实现对直播流画面、语音、评论的分析与处理，为客户提供直播数据分析、直播内容复盘和直播现场风险管理等多维度的服务。',
              infos: [],
              path: '/product/solution/product02',
            },
          ],
        },
        {
          label: '国际传播矩阵',
          value: 'gjcb',
          children: [
            {
              title: '传播矩阵布局解决方案',
              img: require('@/assets/image/product/cp-16.png'),
              id: 'pr6',
              desc: '以时下增速最快的TikTok短视频平台为主，搭建超2000个海外账号的全球传播媒体矩阵，覆盖4大洲、15个国家和地区、12种语言。内容方向涵盖中国多元文化、传承技艺、中式生活方式等，辐射全球年轻人关注的音乐、动漫、服饰、运动等品等多个门类，多角度进行内容创作与分发。人工智能视觉技术赋能，以更快速的反应、更新颖的表现形式和更高效的传播手段进行海外传播与声量扩张。',
              infos: [],
              path: '/product/solution/product03',
            },
            {
              title: '内容传播生产解决方案',
              img: require('@/assets/image/technology/4.png'),
              id: 'pr7',
              desc: '立足于文娱现有的版权资源、传播优势以及专业内容生产团队，打造内容生产传播生态链。深度解析海外视听平台的传播规律、内容偏好及算法机制，打造A级高质量原创账号、B级热门主题账号、C级AI智能生成账号的梯度传播。',
              infos: [],
              path: '/product/solution/product04',
            },
            {
              title: '人机交互传播解决方案',
              img: require('@/assets/image/technology/3.png'),
              id: 'pr8',
              desc: '利用“智能视听内容生产系统”将海量视频素材输出为定制专题的视听内容，满足传播过程中的内容需求，与矩阵账号深度绑定，实现多语种、多地区、多平台内容一键分发。利用“智能人机交互系统”自动化模拟用户行为，通过AI操作点赞、评论、转发、关注及与用户沟通交流等人机交互功能，实现全球声量调度。',
              infos: [
                // {text: '自动化模拟用户行为'},
                // {text: '助力高效矩阵传播'},
              ],
              path: '/product/solution/product05',
            },
          ],
        },
        {
          label: '跨境电商',
          value: 'kjds',
          children: [
            {
              title: '跨境直播解决方案',
              img: require('@/assets/image/product/kj-1.png'),
              id: 'pr9',
              desc: '通过“直播+电商”构建全新的产品出海路径，快速实现企业变现，为企业挖掘更多潜在商业价值。为品牌制定个性化、多样化的跨境直播形式，轻松应对不同种类的跨境直播营销场景，提高营销转化。目前已在北京、青岛、苏州、澳门等地开设直播间基地，精准赋能跨境直播带货。',
              infos: [
                // {text: '北京示范基地'},
                // {text: '青岛综合直播基地'},
                // {text: '苏州文化直播基地'},
              ],
              path: '/product/solution/product06',
            },
            {
              title: '一站式跨境电商解决方案',
              img: require('@/assets/image/product/kj-2.png'),
              id: 'pr10',
              desc: '以出海传播、跨境电商为主要形态发展，致力于帮助中国更多的企业在海外发展业务。全球TikTok Shop已经在英国、美国、东南亚进行开放，中译文娱目前是TikTok官方指定服务商，致力于为更多企业提供TikTok的开拓、内容、直播类服务，包括账号包装、注册，店铺、独立站开设，购物车开设，仓储对接等服务。在TikTok平台上，提供账号运营、直播带货等跨境电商直播营销一站式解决方案。',
              infos: [
                // {text: '全球商品供应链'},
                // {text: '国际品牌打造、国际化传播矩阵'},
                // {text: '海外红人营销、技术赋能'},
              ],
              path: '/product/solution/product07',
            },
            {
              title: '文娱严选解决方案',
              img: require('@/assets/image/product/kj-3.png'),
              id: 'pr11',
              desc: '为品牌海外传播变现打通全能供应链。从内容出发，以中国工艺为本，将高质量文化内容赋能优质产品，产品能效反哺文化输出。做有品质、有品牌、有中华态度的文化产品跨境输出。',
              infos: [
                // {text: '为品牌海外传播变现打通全能供应链'},
                // {text: '做有品质，有品牌，有中华态度的文化产品跨境输出。'},
              ],
              path: '/product/solution/product08',
            },
          ],
        },
      ],
      dynamicData: [
        {
          label: '企业要闻',
          value: 'qyyw',
        },
        {
          label: '业务动态',
          value: 'xwdt',
        },
        {
          label: '传播动态',
          value: 'cbdt',
        },
      ],
      aboutData: [
        {
          label: '公司简介',
          value: 'gsjj',
          path: '/aboutUs/company',
        },
        {
          label: '联系我们',
          value: 'lxwm',
          path: '/aboutUs/contact',
        },
        {
          label: '加入我们',
          value: 'jrwm',
          path: '/aboutUs/join',
        },
      ],
      solutionChildData: [
        {
          title: '视频信息分析解决方案',
          enTitle: 'Video Information Analysis Solutions',
          bg: require('@/assets/image/solution/spxxfxjjfa.png'),
          content: [
            {
              type: 'describe',
              title: '方案概述',
              contnet:
                '视频信息分析解决方案基于全球海量主流视听平台视频内容或本地视频资源素材，通过“以影像数据为核心的数据挖掘”和“多计算引擎的影像内容分析”两项自主研发的核心技术，实现对视频内容的多维信息分析，为客户提供涵盖全球视频信息分析、热点趋势分析和视频内容智能识别与审核等多维度服务的解决方案。',
            },
            {
              type: 'tablesInfo',
              title: '方案架构',
              contnet:
                '可通过公有云、私有云、非云化方式部署构建，依据客户要求定向汇聚全球视听平台数据、构建视频信息分析模型与可视化呈现方式。',
              showArrow: true,
              infos: [
                {
                  title: '数据汇聚',
                  content: ['多源视听数据汇聚'],
                },
                {
                  title: '数据治理',
                  content: ['解码编码', '图像分析', '语音分析', '文字分析'],
                },
                {
                  title: '数据应用',
                  content: ['热点分析', '趋势预测', '情感分析', '风险分析'],
                },
                {
                  title: '数据可视化展示',
                  content: ['热度趋势', '热词词云', '自定义'],
                },
              ],
            },
            {
              type: 'iconInfo',
              title: '应用服务',
              infos: [
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-1-1.svg'),
                  title: '多环境部署',
                  content:
                    '支持公有云、私有云、非云化环境部署，满足不同客户对于视频数据管理策略的个性化要求',
                },
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-1-2.svg'),
                  title: '趋势预测分析',
                  content:
                    '对信息内容、评论、社交媒体情感等多维度信息进行叠加分析，对热点传播趋势进行预测',
                },
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-1-3.svg'),
                  title: '智能识别检测',
                  content:
                    '对视频流中的多主体进行智能识别与检测，深入挖掘分析视频画面中隐藏的信息',
                },
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-1-4.svg'),
                  title: '风险分析预警',
                  content:
                    '通过对视频内容中的信息进行分析，针对潜在的舆论传播风险、安全事故风险提前预警',
                },
              ],
            },
            {
              type: 'imgsInfo',
              title: '应用场景',
              infos: [
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-1-1.png'),
                  title: '传播信息研判',
                  content:
                    '通过对全球主流视听平台发布信息的内容、评论、社交媒体情感等叠加分析，深入洞察全球视频信息内容，为建立完善的传播预警系统提供数据支撑',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-1-2.png'),
                  title: '传播动态管理',
                  content:
                    '通过对全球海量视听平台信息的分析，了解全球热点动态及传播趋势，为国际传播的内容生产提供创作素材，提升国际传播效果',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-1-3.png'),
                  title: '品牌声誉管理',
                  content:
                    '通过对全球海量视听信息及影响市场趋势变化的热点话题、社交事件的挖掘与分析，及时捕捉负面信息，维护公司及品牌的声誉',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-1-4.png'),
                  title: '视频素材管理',
                  content:
                    '通过对视频流中的多主体进行识别、检测和标注，对视频素材库的内容进行智能分类，方便视频分类存储，提升视频素材管理效率',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-1-5.png'),
                  title: '优化市场布局',
                  content:
                    '通过对视频素材中人流量、车流量等信息分析，为企业店铺选址、定向推广等提供侧面支持，帮助企业优化市场布局',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-1-6.png'),
                  title: '视频信息分析引擎定制',
                  content:
                    '利用自主研发的视频信息分析模型定向分析全球视听平台数据及客户本地视频素材，为不同客户提供定制化的视频信息分析引擎',
                },
              ],
            },
          ],
        },
        {
          title: '直播信息分析解决方案',
          enTitle: 'Live Streaming Information Analysis Solutions',
          bg: require('@/assets/image/solution/zbxxfxjjfa.png'),
          content: [
            {
              type: 'describe',
              title: '方案概述',
              contnet:
                '直播信息分析解决方案通过RPA技术、关键帧抽取算法、音视频解码技术等技术实现对直播流的关键信息获取，再利用图像审核算法、文本审核算法，语音识别算法，实现对直播流画面、语音、评论的分析与处理，为客户提供直播数据分析、直播内容复盘和直播现场风险管理等多维度的服务。',
            },
            {
              type: 'tablesInfo',
              title: '方案架构',
              contnet:
                '可根据客户需求进行个性化部署构建，针对客户的要求定向汇聚国内主流直播平台数据、构建直播信息分析模型与可视化呈现方式。',
              showArrow: true,
              infos: [
                {
                  title: '数据汇聚',
                  content: ['机器流程自动化'],
                },
                {
                  title: '数据治理',
                  content: ['视频语义理解', '内容审核', '分布式计算'],
                },
                {
                  title: '数据应用',
                  content: ['风险预警', '信息安全', '取证留存'],
                },
                {
                  title: '数据可视化展示',
                  content: ['异常信息', '直播类型', '异常趋势'],
                },
              ],
            },
            {
              type: 'iconInfo',
              title: '应用服务',
              infos: [
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-2-1.svg'),
                  title: '多维信息分析',
                  content:
                    '提供主流直播平台有关主播数据、直播数据、直播画面、直播语音、直播评论等多维度的信息分析',
                },
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-2-2.svg'),
                  title: '违规内容自动审核',
                  content:
                    '支持自动对直播流中的画面、语音、评论进行审核，防止违规内容传播，造成负面舆论影响',
                },
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-2-3.svg'),
                  title: '风险内容智能处理',
                  content:
                    '根据自动审核结果，支持对违规内容进行实时打码、内容插播、违规强停等智能化处理',
                },
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-2-4.svg'),
                  title: '个性化定制服务',
                  content:
                    '根据客户需求分析不同主播、不同行业等多维度直播信息数据，为客户提供个性化定制服务',
                },
              ],
            },
            // {
            //   type: 'tableInfo',
            //   title: '应用场景',
            //   contnetImg: require('@/assets/image/solution/qqst/qqst-con-2-2.png'),
            // },
            {
              type: 'imgsDoubleInfo',
              title: '应用场景',
              infos: [
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-2-1.png'),
                  title: '直播信息科学研判',
                  content:
                    '通过对直播信息的多维度分析以及对直播内容的自动化审核，为传播监管机构进行传播信息的科学研判提供了依据',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-2-2.png'),
                  title: '传播风险管理',
                  content:
                    '通过对直播过程中违规内容的智能化审核与处理，降低大众传播媒体在直播过程中，突发负面舆论的传播风险；避免在企业品牌电商直播中，出现影响品牌声誉的内容',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-2-3.png'),
                  title: '传媒机构精准服务',
                  content:
                    '提供主流直播平台主播数据、直播数据及直播回放等信息的查询，为传媒机构挖掘优质主播、学习直播技巧、直播运营复盘等提供精准化服务',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-2-4.png'),
                  title: '直播信息分析引擎专业化定制',
                  content:
                    '利用自主研发的直播信息分析模型定向分析国内主流直播平台数据，可为不同客户提供定制化的直播信息分析引擎',
                },
              ],
            },
          ],
        },
        {
          title: '传播矩阵布局解决方案',
          enTitle: 'Communication Matrix Layout Solutions',
          bg: require('@/assets/image/solution/cbjzbjjjfa.png'),
          content: [
            {
              type: 'describe',
              title: '方案概述',
              contnet:
                '以时下增速最快的TikTok短视频平台为主，搭建超2000个海外账号的全球传播媒体矩阵，覆盖5大洲、15个国家和地区、12种语言。内容方向涵盖中国多元文化、传承技艺、中式生活方式等，辐射全球年轻人关注的音乐、动漫、服饰、运动等品等多个门类，多角度进行内容创作与分发。人工智能视觉技术赋能，以更快速的反应、更新颖的表现形式和更高效的传播手段进行海外传播与声量扩张。',
            },
            {
              type: 'tablesInfoThree',
              title: '方案架构',
              contnet:
                '可通过公有云、私有云、非云化方式部署构建，依据客户要求定向汇聚全球视听平台数据、构建视频信息分析模型与可视化呈现方式。',
              showArrow: false,
              infos: [
                {
                  title: 'A类高质量原创账号',
                  content: [
                    '制定年度原创选题，组建专业运营团队，签约专家学者、外交官、流量艺人、海外达人等，联手打造高质量选题内容，提升账号内容的真实感与可信度，提高受众对账号的喜爱程度。',
                  ],
                },
                {
                  title: 'B类热门主题账号',
                  content: [
                    '通过智能内容生产系统筛选海量内容素材，经人工剪辑形成专题视听内容，紧跟时事热点，深度挖掘文化，制造爆款内容，实现垂直内容持续输出。',
                  ],
                },
                {
                  title: 'C类AI智能生成账号',
                  content: [
                    '智能视听内容生产系统智能生成内容，支持多平台一键分发，大规模系统传播，可实现内容批量生成，完成全球声量调度。',
                  ],
                },
              ],
            },
            {
              type: 'imgsInfo',
              title: '应用场景',
              infos: [
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-3-1.png'),
                  title: '政府机构',
                  content:
                    '帮助政府机构搭建多维账号国际传播矩阵，实现精准化、有效化、规模化的内容传播及运营',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-3-2.png'),
                  title: '跨国企业',
                  content:
                    '帮助企业构建完整的国际传播运营体系，通过三类账号的矩阵布局，深入覆盖目标群体，实现品牌在海外的精准营销',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-3-3.png'),
                  title: '文化传播',
                  content:
                    '利用国际传播矩阵进行分级传播，以中国文化内核为主旨，同步制作多层级内容，以实现有效的国际传播',
                },
              ],
            },
          ],
        },
        {
          title: '内容传播生产解决方案',
          enTitle: 'Human-Computer Interaction Communication Solutions',
          bg: require('@/assets/image/solution/cbjzbjjjfa.png'),
          content: [
            {
              type: 'describe',
              title: '方案概述',
              contnet:
                '立足于文娱现有的版权资源、传播优势以及专业内容生产团队，打造内容生产传播生态链。深度解析海外视听平台的传播规律、内容偏好及算法机制，打造A级高质量原创账号、B级热门主题账号、C级AI智能生成账号的梯度传播。',
            },
            // {
            //   type: 'tableInfo',
            //   title: '方案架构',
            //   contnet: '',
            //   contnetImg: require('@/assets/image/solution/qqst/qqst-con-4-1.svg'),
            // },
            {
              type: 'tablesInfo',
              title: '方案架构',
              showArrow: true,
              infos: [
                {
                  title: '构建',
                  content: ['多维度构建优质选题内容'],
                },
                {
                  title: '布局',
                  content: ['布局多国家多语言账号动态传播'],
                },
                {
                  title: '结合',
                  content: ['将传统文化与当代表达有机结合'],
                },
                {
                  title: '键入',
                  content: ['将中国文化键入世界语言体系'],
                },
              ],
            },
            {
              type: 'imgsInfo',
              title: '应用场景',
              infos: [
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-4-1.png'),
                  title: '文化传播',
                  content:
                    '依托文娱现有的文化学者、非遗大师及明星等资源，联合打造优质文化选题内容，利用国际传播矩阵分级传播，提高中国文化国际传播声量及效率',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-4-2.png'),
                  title: '品牌传播',
                  content:
                    '通过品牌在海外的目标群体分析解构，策划垂直精准的传播账号与内容，帮助品牌扩大国际声量，提升在海外的品牌影响力',
                },
              ],
            },
          ],
        },
        {
          title: '人机交互传播解决方案',
          enTitle: 'Human-Computer Interaction Communication Solutions',
          bg: require('@/assets/image/solution/rjjhcbjjfa.png'),
          content: [
            {
              type: 'describe',
              title: '方案概述',
              contnet:
                '利用“智能视听内容生产系统”将海量视频素材输出为定制专题的视听内容，满足传播过程中的内容需求，与矩阵账号深度绑定，实现多语种、多地区、多平台内容分发。利用“智能人机交互系统”提升运营效率，扩大全球范围的传播声量。',
            },
            // {
            //   type: 'tableInfo',
            //   title: '方案架构',
            //   contnet: '',
            //   contnetImg: require('@/assets/image/solution/qqst/qqst-con-5-1.svg'),
            // },
            {
              type: 'tablesInfo',
              title: '方案架构',
              showArrow: true,
              infos: [
                {
                  title: '海量',
                  content: ['从自有的海量版权库提取素材'],
                },
                {
                  title: '智能',
                  content: ['智能生产内核一致'],
                },
                {
                  title: '规模',
                  content: ['画面不同的规模化素材内容'],
                },
                {
                  title: '精准',
                  content: ['一键分发精准触达海外用户'],
                },
              ],
            },
            {
              type: 'imgsInfo',
              title: '应用场景',
              infos: [
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-5-1.png'),
                  title: '政府机构',
                  content:
                    '帮助政府深入全球传播话语体系，提升国家立体形象。依托国外主流社交媒体平台的巨大流量和传播力进行内容的精准触达，有效塑造全面、真实的国家形象',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-5-2.png'),
                  title: '跨国企业',
                  content:
                    '帮助企业有效开拓海外市场，通过制作海外受众喜爱的短视频内容，一键分发，精准触达目标用户，并形成有效交流互动，助力企业实现有效传播和用户收割',
                },
              ],
            },
          ],
        },
        {
          title: '跨境直播解决方案',
          enTitle: 'Cross-border Live Streaming Solutions',
          bg: require('@/assets/image/solution/kjzbjjfa.png'),
          content: [
            {
              type: 'describe',
              title: '方案概述',
              contnet:
                '通过“直播+电商”构建全新的产品出海路径，快速实现企业变现，为企业挖掘更多潜在商业价值。为品牌制定个性化、多样化的跨境直播形式，轻松应对不同种类的跨境直播营销场景，提高营销转化。目前已在北京、青岛、苏州、澳门等地开设直播间基地，精准赋能跨境直播带货。',
            },
            {
              type: 'imgsInfo',
              title: '',
              infos: [
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-6-1.png'),
                  title: '北京示范基地',
                  content: '',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-6-2.png'),
                  title: '青岛综合直播基地',
                  content: '',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-6-3.png'),
                  title: '苏州文化直播基地',
                  content: '',
                },
              ],
            },
            // {
            //   type: 'tableInfo',
            //   title: '方案架构',
            //   contnet: '精准引流获客，通过直播间营销互动，加速变现转化，提升用户留存，搭建品牌专属的电商直播间，实现海外一站式全球卖货。',
            //   contnetImg: require('@/assets/image/solution/qqst/qqst-con-6-1.svg'),
            // },
            {
              type: 'tablesInfo',
              title: '方案架构',
              contnet:
                '精准引流获客，通过直播间营销互动，加速变现转化，提升用户留存，搭建品牌专属的电商直播间，实现海外一站式全球卖货。',
              showArrow: false,
              infos: [
                {
                  title: '海量',
                  content: ['从自有的海量版权库提取素材'],
                },
                {
                  title: '智能',
                  content: ['智能生产内核一致'],
                },
                {
                  title: '规模',
                  content: ['画面不同的规模化素材内容'],
                },
                {
                  title: '精准',
                  content: ['一键分发精准触达海外用户'],
                },
              ],
            },
            {
              type: 'iconInfo',
              title: '应用服务',
              infos: [
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-6-1.svg'),
                  title: '跨境直播场景搭建',
                  content:
                    '满足不同种类产品的需求，制定个性化跨境直播，帮助品牌规划跨境直播流程和场景搭建，为消费者提供沉浸式购物体验，从而触发购买行为',
                },
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-6-2.svg'),
                  title: '打造跨境直播账号',
                  content:
                    '建立品牌流量洼地，以品牌账号为基础，结合跨境直播达人，与信息流广告、网红主播建立合作，双向发力，为带货助力',
                },
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-6-3.svg'),
                  title: '海外本土网红',
                  content:
                    '签约100+海外本土网红，合作超过300位跨境直播主播，触达欧美、东南亚等大部分地区',
                },
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-6-4.svg'),
                  title: '跨境多媒体平台联动',
                  content:
                    '提供多个直播平台，包括亚马逊、TikTok、Facebook、Instagram等电商平台',
                },
              ],
            },
          ],
        },
        {
          title: '一站式跨境电商解决方案',
          enTitle: 'One-stop Cross-border E-commerce Solutions',
          bg: require('@/assets/image/solution/yzskjdsjjfa.png'),
          content: [
            {
              type: 'describe',
              title: '方案概述',
              contnet:
                '以出海传播、跨境电商为主要形态发展，致力于帮助中国更多的企业在海外发展业务。全球TikTok Shop已经在英国、美国、东南亚进行开放，中译文娱目前是TikTok官方指定服务商，致力于为更多企业提供TikTok的开拓、内容、直播类服务，包括账号包装、注册，店铺、独立站开设，购物车开设，仓储对接等服务。在TikTok平台上，提供账号运营、直播带货等跨境电商直播营销一站式解决方案。',
            },
            // {
            //   type: 'tableInfo',
            //   title: '方案架构',
            //   contnet: '从产品分析到落地页制作，从引流视频画面设计到直播跟踪，为客户提供全天代播服务，也可以为客户提供直播指导服务。整个服务流程，将配备专业运营人才和后续的数据分析运营服务，保证最终效果。提供完整直播电商解决方案，建立从开播前的引流推广到直播后营销的完整闭环链路。',
            //   contnetImg: require('@/assets/image/solution/qqst/qqst-con-7-1.svg'),
            // },
            {
              type: 'tablesInfo',
              title: '方案架构',
              contnet:
                '从产品分析到落地页制作，从引流视频画面设计到直播跟踪，为客户提供全天代播服务，也可以为客户提供直播指导服务。整个服务流程，将配备专业运营人才和后续的数据分析运营服务，保证最终效果。提供完整直播电商解决方案，建立从开播前的引流推广到直播后营销的完整闭环链路。',
              showArrow: true,
              infos: [
                {
                  title: '数据分析',
                  content: [
                    '产品分析',
                    'TikTok账号环境搭建',
                    'TikTok账号注册及规划',
                    '头像、背景、介绍等升级',
                  ],
                },
                {
                  title: '落地页制作',
                  content: [
                    'TikTok店铺开设',
                    '购物车开设',
                    '物流对接',
                    '产品落地页制作',
                  ],
                },
                {
                  title: '代直播/指导',
                  content: [
                    '运营培训',
                    'SKU定价建议',
                    '视频拍摄制作',
                    '信息流投放',
                  ],
                },
                {
                  title: '直播跟踪',
                  content: ['直播运营', '策略指导', '数据跟踪', '复盘报告'],
                },
              ],
            },
            {
              type: 'iconInfo',
              title: '应用服务',
              infos: [
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-7-1.svg'),
                  title: '全球商品供应链',
                  content:
                    '云升级优化产品供应链，最大程度支持原创产品，联名产品的开发',
                },
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-7-2.svg'),
                  title: '国际品牌打造',
                  content:
                    '帮助企业挖掘品牌价值、提供品牌特征打造、新文化形态赋能等服务',
                },
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-7-3.svg'),
                  title: '国际化传播矩阵',
                  content: '海外传播、社交互动分享、电商全平台覆盖',
                },
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-7-4.svg'),
                  title: '海外红人营销',
                  content:
                    '长尾效应、扩大知名度，创作贴合本土场景营销的内容，塑造品牌心智',
                },
                {
                  icon: require('@/assets/image/solution/qqst/qqst-icon-7-5.svg'),
                  title: 'AI技术赋能',
                  content:
                    '多模态数据分析“人机协同工作”智能账号下，高效精准赋能直播间',
                },
              ],
            },
          ],
        },
        {
          title: '文娱严选解决方案',
          enTitle: 'Aime Strict Selection Solutions',
          bg: require('@/assets/image/solution/wyyxjjfa.png'),
          content: [
            {
              type: 'describe',
              title: '方案概述',
              contnet:
                '为品牌海外传播变现打通全能供应链。从内容出发，以中国工艺为本，将高质量文化内容赋能优质产品，产品能效反哺文化输出。做有品质、有品牌、有中华态度的文化产品跨境输出。',
            },
            {
              type: 'imgsRowInfo',
              title: '方案架构',
              contnet:
                '围绕空间建设，聚集制造商、传承人、设计师、艺术家等资源的文化产品升级改造链，涵盖300+种文化产品生产库。',
              infos: [
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-8-1.png'),
                  title: '制造商',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-8-2.png'),
                  title: '传承人',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-8-3.png'),
                  title: '设计师',
                },
                {
                  url: require('@/assets/image/solution/qqst/qqst-img-8-4.png'),
                  title: '艺术家',
                },
              ],
            },
          ],
        },
      ],
      productChildData: [
        {
          title: '全球视听信息分析平台',
          enTitle: 'Global Audio-visual Information Analysis Platform',
          bg: require('@/assets/image/solution/qqstfxpt.png'),
          content: [
            {
              type: 'describe',
              title: '产品概述',
              contnet:
                '<div>该系统通过分布式数据挖掘技术、多语种OCR识别技术、多语种语音识别技术、NLP技术，实现全球视听平台的内容数据分析，为用户提供结构化、清晰化的短视频数据查询、动态追踪、研判分析和多维可视化展示。</div><div>该平台可为视听信息分析提供数据支撑；为原创生产、传播内容提供趋势预测；为企业的战略决策提供有建设性的内容参考。</div>',
            },
            {
              type: 'iconInfo',
              title: '产品特性',
              infos: [
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-1-1.svg'),
                  title: '视听信息分析',
                  content: '实时掌握全球视听信息，快速追踪趋势热点',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-1-2.svg'),
                  title: '多维数据呈现',
                  content: '多维度数据快速查询，可视化数据直观呈现',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-1-3.svg'),
                  title: '定制结果报告',
                  content: '创建个性化服务需求，定制化结果报告一键生成',
                },
              ],
            },

            {
              type: 'imgsInfo',
              title: '应用领域',
              infos: [
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-1-1.png'),
                  title: '政府机构',
                  content:
                    '帮助政府机构建立全面的全球视听信息分析服务，为科学决策提有效支撑',
                },
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-1-2.png'),
                  title: '传媒企业',
                  content:
                    '帮助传媒企业了解全球热门视听信息及热点趋势动态，提升传播效果',
                },
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-1-3.png'),
                  title: '跨国企业',
                  content:
                    '通过全球视听信息分析平台为跨国企业提供行业动态分析、市场趋势分析、热点信息追踪等服务，为企业国际传播提供数据支撑',
                },
              ],
            },
          ],
        },
        {
          title: '直播信息分析平台',
          enTitle: 'Live Streaming Information Analysis Platform',
          bg: require('@/assets/image/solution/zbxxfxpt.png'),
          content: [
            {
              type: 'describe',
              title: '产品概述',
              contnet:
                '<div>直播信息分析平台通过RPA技术、关键帧抽取算法、音视频解码技术，实现对直播流的关键信息获取，再利用图像审核算法、文本审核算法，语音识别算法，针对直播画面、弹幕评论、直播语音三方面对直播内容进行分析，可对违规内容进行实时打码、内容插播、违规强停等处理。</div><div>该系统可实现对国内主流直播平台、电视直播的信息分析，为直播平台的信息分析提供数据支持和内容复盘，降低在直播过程中，突发的违规内容、负面舆论等直播风险。</div>',
            },
            {
              type: 'iconInfo',
              title: '核心优势',
              infos: [
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-2-1.svg'),
                  title: '专业的数据分析',
                  // content: '实时掌握全球视听信息，快速追踪趋势热点',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-2-2.svg'),
                  title: '全面的审核维度',
                  // content: '多维度数据快速查询，可视化数据直观呈现',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-2-3.svg'),
                  title: '高效的智能化处理',
                  // content: '创建个性化服务需求，定制化结果报告一键生成',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-2-3.svg'),
                  title: '支持私有化部署',
                  // content: '创建个性化服务需求，定制化结果报告一键生成',
                },
              ],
            },
            // {
            //   type: 'tableInfo',
            //   title: '产品功能',
            //   contnetImg: require('@/assets/image/solution/cpnl/qqst-con-2-1.svg'),
            // },
            {
              type: 'cardsInfo',
              title: '产品功能',
              width: '330px',
              infos: [
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-card-2-1.svg'),
                  title: '自定义主播搜索',
                  content: '支持自定义主播搜索，可查看主播数据及直播记录',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-card-2-2.svg'),
                  title: '直播数据分析',
                  content: '从画面、声音、评论等多维度进行直播数据和内容分析',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-card-2-3.svg'),
                  title: '直播内容回放',
                  content:
                    '支持指定账号在指定时间段内进行直播回放，适用于账号运营复盘',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-card-2-4.svg'),
                  title: '智能审核与处理',
                  content:
                    '智能检测直播画面中存在的违规内容，可对不宜播出的内容进行实时打码、内容插播、违规强停等处理',
                },
              ],
            },
            {
              type: 'imgsInfo',
              title: '应用领域',
              infos: [
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-1-1.png'),
                  title: '政府机构',
                  content:
                    '帮助政府机构完善的直播信息分析，通过多维的信息分析，为负面传播风险的科学研判提供保障',
                },
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-2-2.png'),
                  title: 'MCN机构',
                  content:
                    '为MCN机构提供热门主播数据查询，为学习直播带货技巧及直播后运营复盘提供支持',
                },
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-2-3.png'),
                  title: '广电系统',
                  content:
                    '帮助电视台建立一整套完整的直播内容审核及处理机制，降低违规内容的突发性风险',
                },
              ],
            },
          ],
        },
        {
          title: '影像内容检测系统',
          enTitle: 'Video Content Detection System',
          bg: require('@/assets/image/solution/yxnrjcxt.png'),
          content: [
            {
              type: 'describe',
              title: '产品概述',
              contnet:
                '该系统通过多目标图像识别算法对视频流进行处理，实现对动态影像中多主体的识别与检测。该系统可为行政监管部门、市场调研机构、中小型企业等提供服务，应用场景覆盖场景监控、影像内容审核、人群流量监测等。',
            },
            // {
            //   type: 'tableInfo',
            //   title: '产品功能',
            //   // contnet: '可根据客户需求进行个性化部署构建，针对客户的要求定向汇聚国内主流直播平台数据、构建直播信息分析模型与可视化呈现方式。',
            //   contnetImg: require('@/assets/image/solution/cpnl/qqst-con-3-1.svg'),
            // },
            {
              type: 'cardsInfo',
              title: '产品功能',
              width: '330px',
              infos: [
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-card-3-1.svg'),
                  title: '视频多主体识别',
                  content: '智能检测视频中的多个主体，并自动对其进行标注',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-card-3-2.svg'),
                  title: '目标物体跟踪检测',
                  content:
                    '可对视频中动态变化的物体（如车辆等）进行实时追踪检测',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-card-3-3.svg'),
                  title: '视频内容多维分析',
                  content:
                    '可对视频中的人流量、车流量、特殊标识等内容进行多维度分析',
                },
              ],
            },
            {
              type: 'imgsInfo',
              title: '应用领域',
              infos: [
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-3-1.png'),
                  title: '内容审核',
                  content:
                    '帮助内容审核机构对内容进行精准审核，提升内容审核效率',
                },
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-3-2.png'),
                  title: '安防监管',
                  content:
                    '帮助安防监管机构检测人流量、车流量和特殊标识等，有效降低安全事故的发生',
                },
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-3-3.png'),
                  title: '市场调研',
                  content:
                    '帮助市场调研机构了解CBD、商圈等客流量密集场所的信息，为市场调研分析提供数据支持',
                },
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-3-4.png'),
                  title: '门店销售',
                  content:
                    '帮助门店销售企业了解人流量、车流量等信息，为企业店铺选址、定向推广等提供侧面支持',
                },
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-3-5.png'),
                  title: '文化传媒',
                  content:
                    '帮助文化传媒企业对视频媒体素材库进行审核，便于对视频素材进行分类存储',
                },
              ],
            },
          ],
        },
        {
          title: '智能视听内容生成系统',
          enTitle: 'Intelligent Audio-visual Content Generation System',
          bg: require('@/assets/image/solution/znstnrscxt.png'),
          content: [
            {
              type: 'describe',
              title: '产品概述',
              contnet:
                '该系统通过关键帧识别，拆条，精准提取等方式智能处理视频素材，快速生成目标元视频库。利用知识图谱实体链接/抽取，生成视频“主旨”，基于生成式对抗网络模型实现批量生产同“主旨”、异画面的短视频内容，目前应用于文化传播等场景，可为视听传播所需的海量短视频内容提供支撑。',
            },
            {
              type: 'iconInfo',
              title: '核心优势',
              infos: [
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-4-1.svg'),
                  title: '海量版权素材',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-4-2.svg'),
                  title: '智能生成内容',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-4-3.svg'),
                  title: '多元内容形态',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-4-4.svg'),
                  title: '高效全面传播',
                },
              ],
            },
            {
              type: 'cardsInfo',
              title: '产品功能',
              width: '380px',
              infos: [
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-card-4-1.svg'),
                  title: '智能生成内容',
                  content:
                    '围绕同一主题，将自有素材库内的相关内容整合拼接，批量智能生成视听内容，提高视听内容生成效率，降低制作成本，为大规模视听传播提供素材支撑。',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-card-4-2.svg'),
                  title: '智能生成账号',
                  content:
                    '配合国际传播矩阵的传播需求，在海外主流社交平台上，定制化智能生成账号，提高国际传播矩阵的运营效率，快速扩大国际传播矩阵体量。',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-card-4-3.svg'),
                  title: '一键精准分发',
                  content:
                    '智能分析+智能识别，根据国际传播矩阵不同账号的定位，对智能生成的视听内容进行一键分发，确保视听内容精准触达目标用户，增强国际传播矩阵传播效力。',
                },
              ],
            },
            {
              type: 'imgsInfo',
              title: '应用领域',
              infos: [
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-1-1.png'),
                  title: '政府机构',
                  content:
                    '帮助政府机构搭建国际传播矩阵，批量智能生产宣传内容，提高国际传播声量及传播效率',
                },
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-4-2.png'),
                  title: '跨国企业',
                  content:
                    '帮助企业构建完整的国际传播运营体系，丰富运营方式，扩大品牌传播声量，树立良好的企业形象，提高企业在行业内的影响力',
                },
                {
                  url: require('@/assets/image/solution/cpnl/qqst-img-4-3.png'),
                  title: '传媒企业',
                  content:
                    '帮助文化传媒企业智能生产视频素材，扩展传播素材资源，提高内容生产效率',
                },
              ],
            },
          ],
        },
        {
          title: '智能人机交互系统',
          enTitle: 'Intelligent Human-Computer Interaction System',
          bg: require('@/assets/image/solution/znrjjhxt.png'),
          content: [
            {
              type: 'describe',
              title: '产品概述',
              contnet:
                '该系统可自动化模拟用户行为，助力高效矩阵传播。利用RPA技术、多领域知识图谱及用户画像知识库，实现数字机器人的人物画像生成、人设训练、行为仿真和智能问答等行为，结合传播效果跟踪技术，通过社交网络分析、社交言论分析、话题监测等方式，实现传播效果反馈分析，可应用于跨平台视听信息的国际传播场景，为规模化视听信息传播提供技术支撑。',
            },
            {
              type: 'iconInfo',
              title: '核心优势',
              infos: [
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-5-1.svg'),
                  title: '专业化团队',
                  content: '提供海外本土化数字营销服务',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-5-2.svg'),
                  title: '一站式营销服务',
                  content: '提供精准高效的海外推广',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-5-3.svg'),
                  title: '全渠道资源对接',
                  content: '提供全球产品供应链渠道',
                },
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-icon-5-4.svg'),
                  title: 'AI技术赋能',
                  content: '“人机协同”智能账号高效赋能',
                },
              ],
            },
            {
              type: 'cardsInfo',
              title: '产品功能',
              width: '1160px',
              infos: [
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-card-5-1.svg'),
                  title: '智能人机交互',
                  content:
                    '自动化模拟用户行为，助力高效矩阵传播。面向社交平台渗透的智能AI人机交互系统，借助于NLP和RPA 技术，自动化模拟用户行为，实现智能化点赞、评论、转发、关注及与用户沟通交流等人机交互功能。 ',
                },
              ],
            },
            {
              type: 'oneImg',
              title: '应用领域',
              width: '1160px',
              infos: [
                {
                  icon: require('@/assets/image/solution/cpnl/qqst-img-5-1.png'),
                  title: '互联网企业',
                  content:
                    '帮助有跨境出海需求的企业进行人机交互式渗透传播，利用智能人机交互系统，在海外TikTok平台搭建国际传播矩阵。通过现有的账号集群配合，自动化模拟用户行为，实现AI操作评论、点赞、转发等与目标用户直接产生沟通交流的人机交互动作。侧面干预言论内容，深入评论区，针对不同留言进行互动引导，提升企业在海外的影响力。 ',
                },
              ],
            },
          ],
        },
      ],
    };
  },
};
