<template>
  <div class="foot">
    <div class="about-us">
      <div class="about-info">
        <div class="left">
          <div class="contact-us">
            <div class="contact-info">
              <zy-icon name="icon-dianhua" size="18" color="#CDCDCD"></zy-icon>
              <span class="contact-tel">0532-88912700</span>
            </div>
            <div class="contact-info">
              <zy-icon name="icon-youxiang" size="18" color="#CDCDCD"></zy-icon>
              <span class="contact-tel">aime@gtcom.com.cn</span>
            </div>
          </div>
        </div>
        <!-- <img style="height: 100%;" src="@/assets/网络文化经营许可证.png" alt=""> -->
        <div class="right">
          <div class="infos-item" @click="go('home')">
            <div class="infos-item-title">回到首页</div>
            <!-- <div class="infos-item-content" v-for="(citem, ci) in item.child" :key="ci" @click="go(citem)">{{citem.label}}</div> -->
          </div>
          <div class="infos-item" v-for="(item, i) in infos" :key="i">
            <div :class="item.path !== '' ? 'infos-item-title' : 'infos-item-title-no-link'" @click="go(item)">{{
              item.title }}</div>
            <div class="infos-item-content" v-for="(citem, ci) in item.child" :key="ci"
              @click="citem.blank ? goOutBlank(citem.path) : go(citem)">
              {{ citem.label }}
            </div>
          </div>
          <div class="infos-item attention">
            <div class="infos-item-title">关注我们</div>
            <img style="width: 120px" src="../assets/image/gzh.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="public-info">
      <div class="public-info-des" @click="goOut('company')">
        Copyright 2018-2022 中译文娱科技（青岛）有限公司 鲁ICP备18031571号-4
      </div>
      <div class="public-info-des" @click="go('网络文化经营许可证')">
        网络文化经营许可 鲁网文（2022）0655-010号
      </div>
      <div class="public-info-des imgs" @click="go('出版物经营许可证')">
        出版物经营许可证：新出发鲁字第37B07287号
      </div>
      <!-- <div class="public-info-des police" @click="goOut('police')">
        <img src="../assets/image/police.png" style="margin-right: 3px" /> 鲁公网安备
        37021202000912号
      </div> -->

    </div>
  </div>
</template>

<script>
import data from '@/utils/data';
// import img001 from '@/assets/image/img001.jpg'
import img002 from '@/assets/网络文化经营许可证.png'
import img003 from '@/assets/出版物经营许可证.png'
export default {
  mixins: [data],
  data() {
    return {
      // img001,
      img002,
      img003,
      infos: [
        {
          title: '解决方案',
          path: '/product?id=qqst',
          child: [
            {
              label: '全球视听信息分析',
              path: '/product?id=qqst',
            },
            {
              label: '国际传播矩阵',
              path: '/product?id=gjcb',
            },
            {
              label: '跨境电商',
              path: '/product?id=kjds',
            },
          ],
        },
        {
          title: '产品体系',
          path: '/technology',
          child: [
            {
              label: '全球视听信息分析平台',
              path: '/technology/solution/solution01',
            },
            {
              label: '直播信息分析系统',
              path: '/technology/solution/solution02',
            },
            {
              label: '影像内容检测系统',
              path: '/technology/solution/solution03',
            },
            {
              label: '智能视听内容生产系统',
              path: '/technology/solution/solution04',
            },
            {
              label: '智能人机交互系统',
              path: '/technology/solution/solution05',
            },
          ],
        },
        {
          title: '新闻动态',
          path: '/dynamic',
          child: [
            {
              label: '企业要闻',
              index: '3',
              type: 'qyyw',
              path: '/dynamic?type=qyyw',
            },
            {
              label: '业务动态',
              index: '3',
              type: 'xwdt',
              path: '/dynamic?type=xwdt',
            },
            {
              label: '传播动态',
              index: '3',
              type: 'cbdt',
              path: '/dynamic?type=cbdt',
            },
          ],
        },
        {
          title: '关于我们',
          path: '/aboutUs',
          child: [
            {
              label: '公司简介',
              index: '4',
              type: 'gsjj',
              path: '/aboutUs/company',
            },
            {
              label: '联系我们',
              index: '4',
              type: 'lxwm',
              path: '/aboutUs/contact',
            },
            {
              label: '加入我们',
              index: '4',
              type: 'jrwm',
              path: '/aboutUs/join',
            },
          ],
        },
        {
          title: '友情链接',
          path: '',
          child: [
            {
              label: '中国出版集团',
              index: '5',
              type: 'cnpubg',
              blank: true,
              path: 'http://www.cnpubg.com/',
            },
            {
              label: '中国对外翻译有限公司',
              index: '5',
              type: 'ctpc',
              blank: true,
              path: 'https://www.ctpc.com.cn/cms/',
            },
            {
              label: '中译语通科技股份有限公司',
              index: '4',
              type: 'gtcom',
              blank: true,
              path: 'https://www.gtcom.com.cn/',
            },
          ],
        },
      ],
    };
  },
  methods: {
    // go(val) {
    //   val.path && (this.$router.push(val.path));
    // },
    go(val) {
      if (val == 'home') {
        this.$router.push('/');
      } else if (val.id) {
        let id = val.id;
        this.$router.push('/technology' + (id ? '/#' + id : ''));
      } else if (val.index == '4') {
        let path = val.path + '?id=' + val.type;
        this.$router.push(path);
      } else if (val.index == '3') {
        let path = '/dynamic?type=' + val.type;
        this.$router.push(path);
      } else if (val === '网络文化经营许可证') {
        window.open(this.img002)
      } else if (val === '出版物经营许可证') {
        window.open(this.img003)
      } else {
        val.path && this.$router.push(val.path);
      }
    },
    goOut(key) {
      let path = '';
      key == 'police' &&
        (path =
          'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37021202000912');
      key == 'company' && (path = 'https://beian.miit.gov.cn/#/Integrated/index');
      window.open(path, 'blank');
    },
    goOutBlank(url) {
      window.open(url, 'blank');
    }
  },
};
</script>

<style lang="scss">
.foot {
  // margin-top: 60px;
  width: 100%;
  min-width: 1440px;
  height: 410px;
  background: #142048;
  color: #fff;

  .about-us {
    display: flex;
    // justify-content: center;
    box-sizing: border-box;
    height: 340px;
    width: 100%;
  }

  .about-info {
    padding-top: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .left {
    margin-left: 40px;
  }

  .contact-us {
    .logo {
      img {
        width: 100px;
      }

      margin-bottom: 50px;
    }
  }

  .contact-info {
    margin-top: 25px;
    height: 25px;
    line-height: 25px;
  }

  .contact-tel {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 25px;
    margin-left: 20px;
  }

  .right {
    display: flex;
  }

  .infos-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-right: 76px;

    &:hover {
      .infos-item-title {
        border-bottom: 3px solid #6e59d0;
      }

      .infos-item-content:hover {
        color: #fff;
      }
    }
  }

  .infos-item-title {
    // box-sizing: border-box;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    margin-bottom: 17px;
    padding-bottom: 10px;
    border-bottom: 3px solid rgba(4, 14, 46, 0);
  }

  .infos-item-title-no-link {
    // box-sizing: border-box;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    margin-bottom: 17px;
    padding-bottom: 10px;
  }

  .infos-item-content {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #cdcdcd;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .public-info {
    box-sizing: border-box;
    // height: 70px;
    padding: 12px;
    background: #0b173a;

    text-align: center;
    padding-top: 12px;
  }

  .public-info-des {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #bdbdbd;
    line-height: 17px;
    cursor: pointer;
  }

  .police {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    color: #6dadf6 !important;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .imgs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    color: #bdbdbd;
  }

  .attention {
    display: flex;
    // justify-content: center;
    align-items: center;
  }
}
</style>
