<template>
  <div id="app">
    <nav-bar></nav-bar>
    <router-view></router-view>
  </div>
</template>

<script>
import navBar from '@/components/navBar.vue'
export default {
  name: 'App',
  components: {
    navBar
  },
  mounted() {
    sessionStorage.setItem('showHomeVideo', 'true')
  },
}
</script>

<style lang="scss">
@import './assets/styles/common.scss';
@import './assets/styles/reset.css';

#app {
  min-width: 1440px;
  position: relative;
  min-height: 100vh;
}
</style>
