<template>
  <div class="home">
    <div class="home-carousel">
      <el-carousel height="100vh" trigger="click" @change="changeCar" :interval="6000" :autoplay="true">
        <el-carousel-item v-for="(item, i) in bannerList" @click="go(item)" :key="i">
          <div class="carousel-item">
            <img style="width: 100%" @click="go(item)" :src="item.imgSrc" />
            <div class="carousel-item-content" @click="go(item)" :class="{ show: i == showIndex }">
              <div class="content-title" v-html="item.title"></div>
              <div class="content-dis" v-html="item.content"></div>
              <div class="more-btn" v-if="item.flagHavingDetail == 1">
                了解更多
                <img src="@/assets/image/go.png" alt="" />
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <zy-footer></zy-footer>
    <w-video v-if="playV" :videoinfo="videoInfo" :show="playV" @close="closeVideo"></w-video>
  </div>
</template>

<script>
import { getBannerList } from './api';
import wVideo from '@/components/winVideo.vue';
import homeVideo from '@/assets/video.mp4'
export default {
  data() {
    return {
      bannerList: [],
      showIndex: 0,
      // video info,
      playV: false,
      videoInfo: {
        videoUrl: homeVideo,
        poster: '',
        title: '茉莉花与孔雀舞',
        autoPlay: true,
      },
    };
  },
  components: {
    wVideo,
  },
  mounted() {
    this.getBannerList();
    setTimeout(() => {
      this.playV = sessionStorage.getItem('showHomeVideo') === 'true'
      console.log('this.playV : ', this.playV);
    }, 1000);
  },
  methods: {
    getBannerList() {
      getBannerList().then(({ result }) => {
        result.forEach((item) => {
          if (item.imgSrc && item.imgSrc.indexOf('http') == -1) {
            item.imgSrc = this.$utils.baseImgUrl() + item.imgSrc;
          }
        });
        this.bannerList = result;
      });
    },
    changeCar(e) {
      this.showIndex = e;
    },
    go(val) {
      if (!val.detailUrl) return;
      if (val.detailUrl.indexOf('http') == -1) {
        this.$router.push({ path: val.detailUrl });
      } else {
        window.open(val.detailUrl);
      }
    },
    closeVideo() {
      this.playV = false;
      sessionStorage.setItem('showHomeVideo', 'false')
      this.videoInfo = {};
    },
  },
};
</script>

<style lang="scss">
.home {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  font: 14px/1.5 Helvetica Neue,
  Helvetica,
  Arial,
  Microsoft Yahei,
  Hiragino Sans GB,
  Heiti SC,
  WenQuanYi Micro Hei,
  sans-serif;

  .carousel-item {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .carousel-item-content {
    position: absolute;
    width: 1200px;
    top: 36%;
    // top: 270px;
    // left: calc((100vw - 1400px) / 2);
    left: 9%;
    z-index: 22;

    &:hover {
      cursor: pointer;
    }

    opacity: 0;
  }

  .show {
    opacity: 1;
    transition: opacity 2s;
  }

  .content-title {
    font-size: 1rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
  }

  .content-dis {
    font-size: 0.4rem;
    font-family: SourceHanSansCN;
    font-weight: 400;
    width: calc(100vw * 0.218);

    color: #ffffff;
    margin-top: 20px;
    // line-height: 30px;

    // font-size: 17px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    margin-bottom: 1rem;

    p {
      margin: 0;
      padding: 0;
    }
  }

  .more-btn {
    border: 1px solid #fff;
    width: 210px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;

    img {
      // display: inline-block;
      height: 8px;
      min-height: 8px !important;
      margin-left: 5px;
      width: 24px;
    }
  }
}

.home-carousel {
  // justify-content: center;
}

.home {
  .el-carousel__item img {
    min-height: 100vh;
  }

  .el-carousel__arrow {
    width: 50px;
    height: 50px;
  }

  .el-carousel__arrow i {
    font-size: 16px !important;
  }

  .t-content-enter-active {
    transition: opacity 0.5s;
  }

  .t-content-enter {
    opacity: 0;
  }

  .t-content-leave-active {
    transition: opacity 0.5s;
  }

  .t-content-leave-to {
    opacity: 0;
  }
}
</style>
