<template>
  <div style="display: inline">
    <i class="iconfont default-icon" :class="name" :style="{'color': color,  'font-size': fontSize}"></i>
  </div>
</template>
<script>
import '../common/iconfont/iconfont'
/*
  size: font-size
  color: font-color
  name: iconfont icon`s name ex: "icon-dizhi"
*/
export default {
  props: ['size', 'color', 'name'],
  computed: {
    fontSize() {
      return  parseInt(this.size) + 'px';
    },
  },
  mounted() {
  },
}
</script>

<style >
  .default-icon {
    font-size: 12px;
    display: inline;
    color: #333;
  }
</style>