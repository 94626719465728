import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../pages/home/index.vue'
import Product from '../pages/product/index2.vue'
import Technology from '../pages/technology/index.vue'
import Dynamic from '../pages/dynamic/index.vue'
import DynamicDetails from '../pages/dynamic/details.vue'
import AboutUs from '../pages/aboutUs/index.vue'
import Company from '../pages/aboutUs/company.vue'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/product',
    name: 'product',
    component: Product,
  },
  {
    path: '/product/solution/:type',
    component: () => import('@/pages/publicSolution/index.vue'),
  },
  {
    path: '/technology',
    name: 'Technology',
    component: Technology,
  },
  {
    path: '/technology/solution/:type',
    component: () => import('@/pages/publicSolution/index.vue'),
  },
  {
    path: '/dynamic',
    name: 'dynamic',
    component: Dynamic,
  },
  {
    path: '/dynamic/details',
    name: 'DynamicDetails',
    component: DynamicDetails,
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: AboutUs,
    children: [{
        path: '/',
        component: () => import('@/pages/aboutUs/child/company.vue'),
      },
      {
        path: '/aboutUs/company',
        name: 'company',
        component: () => import('@/pages/aboutUs/child/company.vue'),
      },
      {
        path: '/aboutUs/contact',
        name: 'contact',
        component: () => import('@/pages/aboutUs/child/contact.vue'),
      },
      {
        path: '/aboutUs/join',
        name: 'join',
        component: () => import('@/pages/aboutUs/child/join.vue'),
      },
    ]
  },
  {
    path: '/company',
    name: 'Company',
    component: Company,
  },

]

const router = new VueRouter({
  mode: 'history',
  routes,
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    } else {
      window.scrollTo(0, 0);
    }
  }
})

export default router