<template>
  <div class="about-us-page">
    <zy-header
      :text="{ title: '关于我们', enTitle: 'About Us' }"
      :url="topBg"
    ></zy-header>
    <div class="content">
      <z-nav
        :active="activeName"
        :list="aboutData"
        :showSearch="false"
        @changeTab="handleClick"
      ></z-nav>
      <router-view></router-view>
    </div>
    <zy-footer></zy-footer>
  </div>
</template>

<script>
import data from '@/utils/data';
import topBg from '@/assets/image/gywm-top-bg.png';

export default {
  mixins: [data],
  data() {
    return {
      topBg,
      activeName: 'gsjj',
    };
  },
  watch: {
    '$route.query.id'(id) {
      this.activeName = id;
    },
  },
  methods: {
    handleClick(val) {
      console.log(val);
      this.$router.push({ path: this.aboutData[val.index].path });
    },
  },
  mounted() {
    this.activeName = this.$route.query.id || 'gsjj';
    // this.search()
  },
};
</script>
<style lang="scss" scoped>
.about-us-page {
  .content {
    padding-bottom: 0px;
    background: #f2f2f2;
  }
}
</style>
