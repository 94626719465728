import api from '@/utils/api';

// export async function verifyCode(payload) {
//   return await api.get('login/verifyCode', payload);
// }

export function getNewsList(payload) {
  return api.get('aimePc/newsList', payload);
}
export function newsDetail(payload) {
  return api.get('aimePc/newsDetail', payload);
}
export function spreadPage(payload) {
  return api.get('aimePc/spreadPage', payload);
}